
























































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { Property } from "@/types/property";
import { ArticleProperty } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminShopArticlePropertyTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminShopArticlePropertyTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly articleProperties!: ArticleProperty[];

    private properties: Property[] = [];

    private needsMinValue = false;
    private needsMaxValue = false;

    private defaultArticlePropertyItem: ArticleProperty = {
        id: -1,
        names: [],
        unit: {
            id: -1,
            names: [],
            idAdditive: "",
            image: null,
        },
        value: {
            id: -1,
            value: 0,
            minValue: null,
            maxValue: null,
        },
    };

    private selectedProperty: Property = {
        id: -1,
        names: [],
        units: [],
        needsValue: false,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "names[0].name",
            },
            {
                text: this.$tc("shop.article.property.unit", 1).toString(),
                value: "unit.names[0]",
            },
            {
                text: this.$t("shop.article.property.value").toString(),
                value: "value.value",
            },
            {
                text: this.$t("shop.article.idAdditive").toString(),
                value: "idAdditive",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.properties = await this.fetchProperties();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Function will be called when crud dialog was opened
     * Sets seleectedProperty
     *
     * @param dialogMode dialog mode
     * @param crudItem current crud item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private crudDialogOpened(dialogMode: string, crudItem: ArticleProperty) {
        if (dialogMode === "create") return;

        if (crudItem.value !== null) {
            if (crudItem.value.minValue !== null) {
                this.needsMinValue = true;
            }
            if (crudItem.value.maxValue !== null) {
                this.needsMaxValue = true;
            }
        }

        const property = this.properties.find(
            (prop) => prop.id === crudItem.id
        );
        if (!property) return;

        this.selectedProperty = property;
    }

    /**
     * Function will be called when crud dialog was saved
     * modifies data and emit crudDialogSave
     *
     * @param updatedItems updated items
     * @param dialogMode dialog mode
     * @param crudItem current crud item
     * @param crudItemIndex index of current crud item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private crudDialogSaved(
        updatedItems: ArticleProperty[],
        crudDialogMode: string,
        crudItem: ArticleProperty,
        crudItemIndex: number
    ) {
        if (crudDialogMode !== "delete") {
            updatedItems[crudItemIndex].id = this.selectedProperty.id;
            updatedItems[crudItemIndex].names = this.selectedProperty.names;

            if (!this.selectedProperty.needsValue) {
                updatedItems[crudItemIndex].value = null;
            }
        }

        this.$emit(
            "crudDialogSave",
            updatedItems,
            crudDialogMode,
            crudItem,
            crudItemIndex
        );
    }

    /**
     * Fetches all properties
     *
     * @returns Promise<Property[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchProperties(): Promise<Property[]> {
        const response = (
            await axios.get<APIResponse<Property[]>>("/articles/properties")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
